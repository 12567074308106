<template>
  <div class="sign-in-wrapper">
    <div class="sales-cta">
      <a href="https://orbitalstack.com/" target="blank">
        <img class="sign-in-logo" src="~@/assets/svg/sales-card.svg" alt="Schedule a Demo">
      </a> 
      <div class="cta">
        <p class="big">Rapid insights to guide your design decisions now, saving you from costly mistakes later.</p>
        <div class="button-row"><a class="button" href="https://meetings.hubspot.com/chrissy-lemmex/demo-meeting-link?__hstc=57679477.15ff98b6cf027bfc24c411091436bad3.1707572445976.1742327462682.1742331295376.629&__hssc=57679477.4.1742331295376&__hsfp=1838488382&uuid=221c1b6a-10d4-410b-988c-9c6f357f16b7" target="_blank">Schedule a Demo</a></div>
      </div>
    </div>
    <div class="form-wrapper">
      <div class="sign-in-form">
        <h6 style="color: rgb(0,84,136);">Access Your Orbital Stack Project</h6>
        <p class="pt-2 font-weight-bold" style="font-size:14px">We've sent you a one-time password to the email you provided. Enter it below to access your project</p>
        <hr class="my-3"/>
        <notification-banner v-if="authError"
          theme='error'
          title='Invalid One-Time Password'
          message='Please re-enter your one-time password'
          :show-close-button="false" />
        <FormulateForm class='username closer' @submit="loginWithOneTimePassword">
          <FormulateInput
            label='One-time password'
            element-class="mt-0"
            type='text'
            validation='required'
            v-model='oneTimePassword' />
          <button class="w-100">
            Login
          </button>
        </FormulateForm>
      </div>
    </div>
    <div class="sign-in-footer">
      <p class="smaller">©2025 <a href="https://www.orbitalstack.com/">Orbital Stack</a>. All rights reserved. Unauthorized duplication is strictly prohibited.</p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { NotificationBanner } from 'rwdi-widgets';

import { 
  LOGIN_WITH_ONE_TIME_PASSWORD 
} from '@/store/actions/auth';
import { 
  GET_USER_PROJECT_SHARES, 
  UPDATE_USER_PROJECT_SHARE,
  SET_OTP_PROJECT_SHARE_ID
} from '@/store/actions/permissions';
import { 
  AUTHENTICATED, ERROR 
} from '@/store/statuses/auth';

export default {
  name: 'OTPEmailPage',
  components: {
    NotificationBanner
  },
  mounted() { },
  data() {
    return {
      oneTimePassword: null
    };
  },
  computed: {
    authError() {
      return this.authState.status === ERROR;
    },
    ...mapGetters(['authState', 'userProjectShares']),
    ...mapGetters('project', ['selectedProject'])
  },
  methods: {
    async loginWithOneTimePassword() {
      // login with one-time password
      const payload = {
        username: this.$route.query.otp_key,
        password: this.oneTimePassword
      };
      await this.$store.dispatch(LOGIN_WITH_ONE_TIME_PASSWORD, payload);

      if (this.authState.status === AUTHENTICATED) {
        // get project share from psid
        await this.$store.dispatch(GET_USER_PROJECT_SHARES);
        let projectShare = this.userProjectShares.find(share => share.id == this.$route.query.psid);
        this.$store.dispatch(SET_OTP_PROJECT_SHARE_ID, {
          projectShareId: projectShare.id
        });

        // accept project share
        if (projectShare.share_status === 'PENDING') {
          await this.$store.dispatch(UPDATE_USER_PROJECT_SHARE, {
            projectShareId: projectShare.id,
            share_status: 'ACCEPTED'
          });
        }

        // get shared project
        await this.getProject(projectShare.project.id);

        // redirect to shared project
        const firstStudy = this.selectedProject.studies.at(0);
        let sim = firstStudy.simulation_labels.find(label => label.category === 'RESULT');
        if(!sim) sim = firstStudy.simulation_labels.at(0);
        let tabName = 'Viewer';
        let query = {};
        switch(projectShare.share_content) {
        case 'INSIGHTS':
          tabName = 'insights';
          query = { page: 0 };
          break;
        }
        if(sim) {
          this.$router.push({
            name: 'ViewerContainer',
            params: {
              id: this.selectedProject.id,
              study: firstStudy.id,
              configuration: sim.configurationId,
              tabName: tabName
            },
            query
          });
        } else {
          this.$router.push({
            name: 'ViewerContainerNoScenarios',
            params: {
              id: this.selectedProject.id,
              tabName: tabName
            },
            query
          });
        }
      }
    },
    ...mapActions({
      getProject: 'project/getProject'
    })
  }
};
</script>

<style scoped>

*, * > * {
  box-sizing: border-box!important;
}

body, html {
  height: 100vh;
}

.sign-in-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  padding: 0;
  margin: 0;
  background-color: var(--primary-navy);
}

.sales-cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: center;
  padding: 1.5rem;
  background-color: var(--primary-navy);
}

.sales-cta {
  color: #fff;
}

.cta {
  display: none;
}

.sign-in-footer {
  color: #fff;
  padding: 1em;
  text-align: center;
}

.sign-in-footer a {
  color: #fff;
}

a.button {
  text-decoration: none;
}

.sales-cta a {
  color: #fff;
  margin: 0 auto;
}

.sign-in-logo {
  width: 100%;
  height: auto;
  max-width: 15.625rem;
  padding: 1em

}

.form-wrapper {
  margin: 0;
  flex-grow: 2;
}

.sign-in-form {
  background-color: #fff;
  padding: 2em;
  border-radius: 0.313rem;
  box-shadow: 0 0 0.313rem 0.188rem rgba(0, 0, 0, 0.1);
  max-width: 25rem;
  margin: 0 auto;
}

.sign-in-form .button-row {
  justify-content: space-between;
}

.sign-in-form a {
  align-self: center;
}

.sign-in-form h4 {
  font-size: 1.25em;
}

.sign-in-button {
  width: 100%;
}

.no-top-margin-all-descendants * {
	margin-bottom: 0.5em;
}
.no-top-margin-all-descendants .input-title{
  margin-bottom: 1.5em !important;
  margin-top: 0.2em !important;
}

.submit-button {
  margin-top: 2em;
}

@media screen and (min-width: 52rem) {

  .sign-in-wrapper {
    flex-direction: row;
    background-color: var(--grey-200);
  }

  .cta {
    display: block;
    margin: auto 0;
  }

  .sales-cta {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: auto;
    max-width: 25rem;
    height: 100%;
    text-align: center;
    padding: 1.5rem;
    background-color: var(--primary-navy);
  }

  .sign-in-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 25rem;
  }
}

</style>

<style>
.submit-button .formulate-input-wrapper .formulate-input-element--submit button{
  background-color: var(--primary-blue);
  width: 100%;
  text-align: center !important;
  margin: 0 auto;
  display: inline;
  border: 0.66px solid rgb(0,84,136);
}
.formulate-input .formulate-input-wrapper .formulate-input-element {
  max-width: none !important;
}
</style>